import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PageBanner from '../components/PageBanner/index'
import InnerPages from '../components/PageComponent/InnerPages/index'
import ContactUs from '../components/PageComponent/HomePageComponent/ContactUs/index'
import { Section, Grid, Spacer } from '../components/Globals/index.style'
import NewFooter from '../components/NewFooter/index'
import Form from '../components/Form/index'
import BlockFeatureCard from '../components/BlockFeatureCard/index'
import CallToAction from '../components/CallToAction/index'
import BlockCards from '../components/BlockCards/index'
import _ from 'lodash'

const ProductTemplate = ({ data }) => {
  const { banner, contactUs, footer, tabActions, sections } =
    data?.contentfulProductOp
  const { title, bgImage, withNavbar, subtitle, images, actions, isCenter } =
    data?.contentfulProductOp?.banner
  const { footerCards, subFooterTitle } = data?.contentfulProductOp?.footer
  const [footerPart1, footerPart2, footerPart3, footerPart4] = footerCards
  const [subSection, isGreen] = sections
  console.log(data?.contentfulProductOp)
  // console.log(subSection)

  // const { title, customTitle, subtitle, seo } = data.contentfulProduct
  // const bannerImg = data.contentfulProduct.banner.file.url
  // const blocks = data.contentfulProduct.contentCardBlock || ''
  // const call2action = data.contentfulProduct.callToAction || ''
  // const cardblocks = data.contentfulProduct.blockCards || []
  // const modularblockcard = cardblocks[0].cards || []

  // const blockCard = useMemo(
  //   () =>
  //     modularblockcard.map((card) => {
  //       const testUrl = typeof card.externalLink === 'string'

  //       return {
  //         imageSrc: card.image.file.url,
  //         imageAlt: card.title,
  //         ctaText: card.callToActionText,
  //         text: card.cardDescription
  //           ? card.cardDescription.cardDescription
  //           : '',
  //         content: card.content ? card.content : '',
  //         url: testUrl ? card.externalLink : card.internalLink.slug || null,
  //         highlight: cardblocks[0].highlightText,
  //       }
  //     }),
  //   [modularblockcard]
  // )

  return (
    <>
      <Helmet>{/* <title>{seo}</title> */}</Helmet>

      {banner && (
        <PageBanner
          title={title}
          bgImage={bgImage}
          actions={actions}
          subtitle={subtitle}
          isCenter={isCenter}
        />
      )}

      {sections.length !== 0 ? (
        <>
          {tabActions.length !== 0 && (
            <InnerPages
              tabActions={tabActions}
              cards={subSection.subSections}
              isGreen={isGreen}
            />
          )}
        </>
      ) : null}

      {contactUs && (
        <ContactUs
          title={contactUs.title}
          subtitle={contactUs.subtitle}
          description={contactUs.description.description}
          actions={contactUs.actions}
          file={contactUs.file.file.url}
        />
      )}

      {footer && (
        <NewFooter
          footerPart1={footerPart1}
          footerPart2={footerPart2}
          footerPart3={footerPart3}
          footerPart4={footerPart4}
          subFooterTitle={subFooterTitle}
        />
      )}

      {/* <PageBanner
        title={title}
        customTitle={customTitle}
        subtitle={subtitle}
        image={bannerImg}
      /> */}

      {/* <Section className="Featured-section">
        <BlockFeatureCard
          title={blocks.title}
          subtitle={blocks.subtitle}
          content={blocks.content.json}
          videoLink={blocks.videolink && blocks.videolink.file.fileName}
          image={blocks.image && blocks.image.file.url}
        />
      </Section> */}

      {/* <CallToAction
        isEnable={call2action.isEnable ? true : false}
        center={call2action.center}
        title={call2action.title}
        descriptions={
          call2action.descriptions ? call2action.descriptions.descriptions : ''
        }
        primaryButton={{
          href: call2action.externalLink ? call2action.externalLink : '',
          text: call2action.buttontext,
          to: call2action.internalLink ? call2action.internalLink : '',
        }}
      /> */}

      {/* <Section className="cardlist-section">
        <Grid>
          <BlockCards
            title={data.contentfulProduct.blockCards[0].title}
            subtitle={data.contentfulProduct.blockCards[0].subtitle.subtitle}
            cards={blockCard}
            ctaAsLink
          />
        </Grid>
      </Section> */}

      {/* <Section className="form-section disaAbletopMargin">
        <Form />
      </Section> */}
    </>
  )
}

export const query = graphql`
  query ProductQuery($slug: String!) {
    contentfulProductOp(slug: { eq: $slug }) {
      id
      slug
      banner {
        title
        subtitle
        slug
        bgImage {
          file {
            url
          }
        }
        actions {
          to
          text
        }
        withNavbar
        isCenter
      }
      name
      tabActions {
        text
        to
      }
      footer {
        subFooterTitle
        slug
        footerCards {
          title
          subtitle
          slug
          actions {
            to
            text
          }
          file {
            file {
              url
            }
          }
          pages {
            slug
            name
            title
          }
        }
      }
      contactUs {
        title
        subtitle
        slug
        file {
          file {
            url
          }
        }
        description {
          description
        }
        actions {
          to
          text
        }
      }
      sections {
        actions {
          to
          text
        }
        isGreen
        slug
        subSections {
          actions {
            to
            text
            artBoard
            bed
            shower
          }
          title
          subtitle
          slug
          file {
            file {
              url
            }
          }
          description {
            description
          }
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          content {
            childMarkdownRemark {
              excerpt(pruneLength: 100)
              html
            }
          }
          username
        }
      }
    }
  }
`
export default ProductTemplate
