import React from 'react'
import {
  Container,
  Box,
  Text,
  Grid,
  Avatar,
  Button,
  Select,
  SimpleGrid,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ExtraInfoCard from '../PropertiesPageComponent/DiscoverPropertiesDetails/Cards/extraInfoCard'
import { Row, Col } from 'react-styled-flexboxgrid'
import { RightArrowHover, LeftArrowSvg } from '../../../assets/svgs'

const RightArrowSvgIcon = (props) => {
  return <RightArrowHover {...props} />
}

const LeftArrowSvgIcon = (props) => {
  return <LeftArrowSvg {...props} />
}

export const BackPageButtonWrapper = styled(Button)`
  &:hover {
    svg {
      fill: #ffffff;
    }
  }
  &:focus {
    svg {
      fill: #ffffff;
    }
  }
`

export const NextPageButtonWrapper = styled(Button)`
  &:hover {
    svg {
      fill: #ffffff;
    }
  }
  &:focus {
    svg {
      fill: #ffffff;
    }
  }
`

const InnerPageWrapper = styled.section`
  display: inline-block;
  width: 100%;
  padding: 50px 0px;
  background: #fafff4;
`

const StyledSelect = styled(Select)`
  color: #ffffff;
  border-color: #83b53c !important;

  > option {
    color: #83b53c;
  }
`

const RowWrapper = styled(Row)`
  gap: 30px 0px;
`
const ColumnWrapper = styled(Col)`
  padding-right: 1.1rem;
  padding-left: 1.1rem;
`

const PaginationButtonWrapper = styled(Row)`
  gap: 30px;
`

const BackPageButton = styled(BackPageButtonWrapper)``

const NextPageButton = styled(NextPageButtonWrapper)``

const InnerPages = ({ tabActions = [], cards = [], isGreen }) => {
  return (
    <InnerPageWrapper>
      <Container
        maxW={['container.sm', 'container.md', 'container.lg', 'container.xl']}
      >
        {console.log(cards)}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          backgroundColor="#FFFFFF"
          border="0.5px solid #83B53C"
          borderRadius="10px"
          padding="20px 20px"
          marginBottom="43px"
        >
          <SimpleGrid
            display={['none', '', 'flex', 'flex']}
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
            spacing={['15px 37px', '15px 37px', '15px 37px', '15px 37px']}
            columns={[6, 6, 6, 6]}
          >
            {(tabActions || []).map((item) => (
              <Box display="flex">
                <Button
                  as={Link}
                  to={`/${item.to}`}
                  variant="solid"
                  fontSize="14px"
                  color="#83B53C"
                  background="transparent"
                  outline="none !important"
                  boxShadow="none"
                  borderRadius="0px"
                  width="159px"
                  height="59px"
                  border="0.5px solid #83B53C"
                  borderRadius="10px"
                  _hover={{ background: '#83B53C', color: '#FFFFFF' }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  focusBorderColor="#83B53C"
                  _activeLink={{
                    background: '#83B53C',
                    color: '#FFFFFF',
                  }}
                >
                  {item.text}
                </Button>
              </Box>
            ))}
          </SimpleGrid>
          <Box display={['flex', 'flex', 'none', '']} width="100%">
            <StyledSelect
              placeholder="Select option"
              backgroundColor="#83B53C"
              // color="#FFFFFF"
              _focus={{
                boxShadow: 'none',
                borderColor: 'transparent',
              }}
              // _selected={{
              //   backgroundColor: '#83B53C',
              // }}

              // _active={{
              //   backgroundColor: '#83B53C',
              // }}
            >
              {(tabActions || []).map((item) => (
                <>
                  <option value={item.text}>{item.text}</option>
                </>
              ))}
            </StyledSelect>
          </Box>
        </Box>
        <Box display="flex" width="100%" marginBottom="47px">
          <Box
            padding="77px 20px 83px"
            border="2px solid #83B53C"
            borderRadius="20px"
            background="#FFFFFF"
            width="100%"
          >
            <RowWrapper>
              {(cards || []).map((card) => (
                <ColumnWrapper xs={12} sm={6} md={4} lg={4}>
                  <ExtraInfoCard card={card} isGreen={isGreen} />
                </ColumnWrapper>
              ))}
            </RowWrapper>
          </Box>
        </Box>
        <Row>
          <Col xs={12}>
            <Row center="xs">
              <Col xs={12} sm={6}>
                <PaginationButtonWrapper center="xs">
                  <BackPageButton
                    variant="solid"
                    fontSize="14px"
                    color="#83B53C"
                    background="transparent"
                    outline="none !important"
                    boxShadow="none"
                    borderRadius="0px"
                    width={['90px', '100px', '159px', '159px']}
                    height="59px"
                    border="0.5px solid #83B53C"
                    borderRadius="18px 0px 0px 0px"
                    _hover={{ background: '#83B53C', color: '#FFFFFF' }}
                    _focus={{
                      boxShadow: 'none',
                      background: '#83B53C',
                      color: '#FFFFFF',
                    }}
                    focusBorderColor="#83B53C"
                    leftIcon={<LeftArrowSvgIcon color="#83B53C" />}
                  >
                    Back
                  </BackPageButton>
                  <NextPageButton
                    variant="solid"
                    fontSize="14px"
                    color="#83B53C"
                    background="transparent"
                    outline="none !important"
                    boxShadow="none"
                    borderRadius="0px"
                    width={['90px', '100px', '159px', '159px']}
                    height="59px"
                    border="0.5px solid #83B53C"
                    borderRadius="0px 18px 0px 0px"
                    _hover={{ background: '#83B53C', color: '#FFFFFF' }}
                    _focus={{
                      boxShadow: 'none',
                      background: '#83B53C',
                      color: '#FFFFFF',
                    }}
                    focusBorderColor="#83B53C"
                    rightIcon={<RightArrowSvgIcon color="#83B53C" />}
                  >
                    Next
                  </NextPageButton>
                </PaginationButtonWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </InnerPageWrapper>
  )
}

export default InnerPages
